import React from "react";
import Hero from "../components/Hero";

import Standplaats from "../assets/standplaats-1.webp";
import Bannerstand from "../assets/bannerstand-1.webp";
import Praattafel from "../assets/praattafel-1.webp";

const Prijzen = () => {
    return (
        <div className="prijzen__container">
            <Hero heroTitle="Beursplaatsen"/>
            <div className="prijzen__header">
                <h2>Prijzen beursplaatsen</h2>
                <p>Hieronder zijn de verschillende beursplaatsen te zien. Alle beursplaatsen zijn voor beide dagen, 25 & 26 november.
                    Neem contact met ons op via info@geobuzz.nl om een beursplaats te reserveren.
                </p>
            </div>
            <div className="prijzen__content-container">
                <div className="prijzen__beursplaats">
                    <img src={Standplaats}/>
                    <div className="prijzen__beursplaats-content">
                        <h2>Standplaats</h2>
                        <p>€ 2.950</p>
                        <ul>
                        <li>Een vloerruimte van 3 meter diep en 4 meter breed.</li>
                            <li>De standplaats is excl. standbouw.</li>
                            <li>1 stroompunt.</li>
                            <li>Vrij gebruik van het openbare Wifi-netwerk.</li>
                            <li>Prijs is excl. BTW.</li>
                        </ul>
                    </div>
                </div>
                <div className="prijzen__beursplaats">
                    <img src={Bannerstand}/>
                    <div className="prijzen__beursplaats-content">
                        <h2>Bannerstand</h2>
                        <p>€ 1.850</p>
                        <ul>
                            <li>Een plaats van 3 meter breed</li>
                            <li>1 statafel & 2 barkrukken</li>
                            <li>Print van 2 x 1,5 meter.</li>
                            <li>1 stroompunt.</li>
                            <li>Vrij gebruik van het openbare Wifi-netwerk.</li>
                            <li>Prijs is excl. BTW.</li>
                        </ul>
                    </div>
                </div>
                <div className="prijzen__beursplaats">
                    <img src={Praattafel}/>
                    <div className="prijzen__beursplaats-content">
                        <h2>Praattafel</h2>
                        <p>€ 1.350</p>
                        <ul>
                            <li>Een witte tafel: 1,6m lang en 0,6m breed</li>
                            <li>Een gele wand: 1m breed en 2,5m hoog met logobordje</li>
                            <li>2 barkrukken (wit)</li>
                            <li>1 stroompunt.</li>
                            <li>Vrij gebruik van het openbare Wifi-netwerk.</li>
                            <li>Prijs is excl. BTW.</li>
                        </ul>
                    </div>
                </div>
                <div className="prijzen__beursplaats">
                    <img src={Bannerstand}/>
                    <div className="prijzen__beursplaats-content">
                        <h2>Data inwin plein 16m2</h2>
                        <p>€ 2.950</p>
                        <ul>
                            <li>Een vloerruimte van 4 meter diep en 4 meter breed.</li>
                            <li>Mogelijkheid om hardware of producten tentoon te stellen.</li>
                            <li>De standplaats is incl. standbouw.</li>
                            <li>1 stroompunt.</li>
                            <li>Vrij gebruik van het openbare Wifi-netwerk.</li>
                            <li>Prijs is excl. BTW.</li>
                        </ul>
                    </div>
                </div>
                <div className="prijzen__beursplaats">
                    <img src={Bannerstand}/>
                    <div className="prijzen__beursplaats-content">
                        <h2>Data inwin plein 12m2</h2>
                        <p>€ 1.850</p>
                        <ul>
                            <li>Een vloerruimte van 3 meter diep en 4 meter breed.</li>
                            <li>Mogelijkheid om hardware of producten tentoon te stellen.</li>
                            <li>De standplaats is incl. standbouw.</li>
                            <li>1 stroompunt.</li>
                            <li>Vrij gebruik van het openbare Wifi-netwerk.</li>
                            <li>Prijs is excl. BTW.</li>
                        </ul>
                    </div>
                </div>
                <div className="prijzen__beursplaats">
                    <img src={Praattafel}/>
                    <div className="prijzen__beursplaats-content">
                        <h2>Data inwin praattafel</h2>
                        <p>€ 1.350</p>
                        <ul>
                            <li>Een witte tafel: 1,6m lang en 0,6m breed</li>
                            <li>Mogelijkheid om hardware of producten tentoon te stellen.</li>
                            <li>Een gele wand: 1m breed en 2,5m hoog met logobordje</li>
                            <li>2 barkrukken (wit)</li>
                            <li>1 stroompunt.</li>
                            <li>Vrij gebruik van het openbare Wifi-netwerk.</li>
                            <li>Prijs is excl. BTW.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Prijzen;