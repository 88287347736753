import React, { useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";

// import { ReactComponent as MapSVG } from "/media/GeoBuzz-Plattegrond-schaal-2025.svg";

const Beurs = () => {

    return (
        <div className="container-beurs">
            <Hero heroTitle={"Beurs"} />
            <h2 className="header_info1">Plattegrond</h2>
            <p className="info_beurs">Hieronder ziet u de beursplattegrond voor 17 en 18 november 2025. De standnummers waar nog geen naam achter staat
                zijn nog beschikbaar. De plattegrond kan nog aangepast worden. <Link to="/reserveren">Reserveer hier</Link> een beursplaats.</p>
            <img src="/media/GeoBuzz-Plattegrond-schaal-2025.svg" alt="Plattegrond" className="map"/>
        </div>
    )
}

export default Beurs;